import React, { useEffect, useState, useRef, useContext } from "react"
import DynamicForm from "../../../FormBuilder/DynamicForm"
import PatientAdditionalInfoService from "../PatientAdditionalInfoService"
import querystringify from "querystringify"
import FormContainer from '../../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../../components/Common/GeoLocation"
import { CountryCodes } from "../../../../constants/CountryCodes"
import { useCurrentUserHook } from "../../../Authentication/useUserHook"

function PatientAdditionalInfoForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const {currentUser} = useCurrentUserHook();

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let forigenObject =  props.location.state
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const currentOrgData = currentUser.current_organization?.data || {}

  const get = () => {
    PatientAdditionalInfoService.get(params.id).then((res) => {
      if (res.status == 200) {
        scope.additionalInfo = {...res.data.additional_info, ...scope.additionalInfo.geo_data}
        scope.data = scope.additionalInfo.data || {}
 
        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function (res) {
      scope.additionalInfo = null
    })
  }

  useEffect(() => {
    scope.additionalInfo = {}
    scope.data = {}
    const f = currentOrgData.country_code?
      CountryCodes[currentOrgData.country_code.toLowerCase()]:null
    
      scope.formFn = {
      "form_type": "health.additional_info",
      "journey_profile_id": params.journey_profile_id,
      "phone_display_format": f?.[4],
      "country_code": f?.[2],
    }
    scope.formMode = "create-form-submissions"
    if(forigenObject)scope.data = Object.assign(forigenObject, scope.data); 
    if (params.id) {
      get();
      scope.formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (additionalInfo) => {
    additionalInfo.organization_id = currentUser.current_organization_id
    additionalInfo.created_by = currentUser.id
    additionalInfo.updated_by = currentUser.id
    additionalInfo.medical_facility_id = currentUser.current_medical_facility_id
    additionalInfo.journey_profile_id = params.journey_profile_id
    additionalInfo.form_id = parseInt(queryParam.form_id)
    additionalInfo.form_type = queryParam.label
    PatientAdditionalInfoService.create(additionalInfo).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect(res.data.additional_info)
      }
    }).catch((res) => {

    })
  }

  const update = (additionalInfo) => {
    additionalInfo.updated_by = currentUser.id
    additionalInfo.medical_facility_id = currentUser.current_medical_facility_id

    PatientAdditionalInfoService.update(additionalInfo).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect(additionalInfo)
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (additionalInfo) => {
    if(props.isPopupMode){
      props.onFormSubmit(additionalInfo)
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={queryParam.form_id}
          form={scope.additionalInfo}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={scope.additionalInfo}/>
      </FormContainer>
    )
  } 

  return null;
}

export default PatientAdditionalInfoForm;