import React, { useReducer, useEffect, lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { lazily } from 'react-lazily';
import PrivateRoute from "../../routes/PrivateRoute";
import PublicRoute from "../../routes/PublicRoute";
import HealthJourneyList from './List/HealthJourneyList';
import HealthJourneyForm from '../../components/HealthJourney/Form/HealthJourneyForm';
import HealthRouter from "./Health/HealthRouter";
import PortalPage from './GenericPortalPage/PortalPage';
import { JourneyContext, initialJourneyState } from "./List/JourneyContext";
import JourneyReducer from './List/JourneyReducer';
import InfantJourneyRouter from './MotherInfantJourney/Router';
import FormList from '../Offline/FormList';
import { useCurrentUserHook } from '../Authentication/useUserHook';
import { Spinner } from '../Common/Spinner';

const RoleList = lazy(() => import('../../components/Roles/RoleList'));
const IncomingMails = lazy(() => import('./IncomingMails/IncomingMails'));
const MemberList = lazy(() => import('../Members/MemberList'));
const HealthImageMap = lazy(() => import('./GenericPortalPage/HealthImageMap'));
const WellnessForm = lazy(() => import('./Wellness/Form/WellnessForm'));
const TreatmentList = lazy(() => import('./Treatments/List/TreatmentList'));
const TreatmentForm = lazy(() => import('./Treatments/Form/TreatmentForm'));
const WellnessBlockPage = lazy(() => import('./Wellness/List/WellnessBlockPage'));
const UpcomingDashboard = lazy(() => import('./Dashboard/UpcomingDashboard'));
const {OrganizationMediaPage} = lazily(() => import("./CaseReports/OrganizationMediaFiles"));
const JourneyReviewCommentList = lazy(() => import("./Dashboard/JourneyReviewCommentList"));
const CaseAssessmentList = lazy(() => import("./CaseAssessments/CaseAssessmentList"));
const PreTrainingCaseList = lazy(() => import("./List/PreTrainingCaseList"));

const HealthJourneyRouter = (props) => {
  const {currentUser} = useCurrentUserHook();
  const [journeyState, journeyDispatch] = useReducer(JourneyReducer, initialJourneyState);

  useEffect(()=>{
    journeyDispatch({'type': "CLEAR"});
  }, [
    currentUser.current_locale, 
    currentUser.current_medical_facility_id, 
    currentUser.current_organization_id, 
    currentUser.current_sub_facility_id
  ])

  return(
    <>  
      <JourneyContext.Provider value={{journeyState, journeyDispatch}}>
        <Switch>
          <PrivateRoute path="/health/form/:form_id/:id?" component={HealthJourneyForm} exact />
          <PublicRoute path="/public/health/form/:form_id" component={HealthJourneyForm} exact />
          <PrivateRoute path={["/health/table"]} searchString={props.searchString} component={HealthJourneyList} exact />
          <PrivateRoute path="/health/common/page/:journey_profile_id" component={PortalPage} exact />
          <PrivateRoute path="/offline" component={FormList} exact/>
          <InfantJourneyRouter searchString={props.searchString}/>
        </Switch>
      </JourneyContext.Provider>
      <Switch>
        <Suspense fallback={<Spinner/>}>
          <PrivateRoute path="/health/role/:roleable_type/:roleable_id/:organization_id/:subscription_id" searchString={props.searchString} component={RoleList} exact />
          <PrivateRoute path="/health/role/templates/:subscription_id" searchString={props.searchString} component={RoleList} exact />
          <PrivateRoute path="/health/incoming/emails/:type/:id" component={IncomingMails} exact />
          <PrivateRoute path="/health/members/:memberable_type/:memberable_id" component={MemberList} exact/>
          <PrivateRoute path="/health/map/:formId?" component={HealthImageMap} exact />
          <PrivateRoute path="/health/treatment/:health_id?" searchString={props.searchString} component={TreatmentList} exact />
          <PrivateRoute path="/health/treatmentForm/:journey_profile_id/:id?" searchString={props.searchString} component={TreatmentForm} exact />
          <PrivateRoute path="/health/wellnessForm/:journey_profile_id/:symptom_id/:type/:form_id/:id?" searchString={props.searchString} component={WellnessForm} exact /> 
          <PrivateRoute path="/health/treatmentForm/:journey_profile_id/:treatment_id/:treatment_type/:treatment_form_id/:id?" searchString={props.searchString} component={TreatmentForm} exact />   
          <PrivateRoute path="/wellness/graph/:journey_profile_id" component={WellnessBlockPage} exact />
          <PrivateRoute path="/health/upcoming/dashboard" component={UpcomingDashboard} exact />
          <PrivateRoute path="/org/collections/:organization_id" searchString={props.searchString} component={OrganizationMediaPage} exact />
          <PrivateRoute path="/health/comment_review/page" component={JourneyReviewCommentList} exact />
          <PrivateRoute path="/case/:journey_profile_id/assessments/:user_id" component={CaseAssessmentList} exact />
          <PrivateRoute path="/mcj/pretraining/case/list" component={PreTrainingCaseList} searchString={props.searchString} exact/>
        </Suspense>
      </Switch>
      <Switch>
        <HealthRouter searchString={props.searchString} setHelpId={props.setHelpId} />
      </Switch>
    </>
  )
}

export default HealthJourneyRouter;