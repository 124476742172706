import React, {Fragment} from "react";
import {getLocale} from '../FormBuilder/FieldLabel';

const Badge = ({show=true, className, children}) => (show ?
  <span className={"badge-2 "+(className || '')}>{children}</span>
  :
  null
)

const PillList = ({list, dataKey, style, onSelect}) => list && list.length > 0 ? list.map((n, i) =>{
  if(n == null) return null;
  
  const props = {
    className:style?style:'text-cap round-tab bg-highlight white m-r-5'
  }

  if(onSelect) props.onClick = () => onSelect(n);

  return(
    <span {...props} key={i}>
      {typeof n === 'object' ? n[dataKey] : n} 
    </span>
  )
}):null

const PermissionList = ({list, obj}) => list && Object.keys(list).length>0 ? Object.keys(list).map((n, i) =>
  <span className="text-cap round-tab bg-yellow-white" key={i}>{obj[n]}&emsp;</span>
): <span>None</span>

const NotificationList = ({list, obj}) => list && list.length>0 ? list.map((n, i) =>
  <span key={i} className="text-cap round-tab bg-yellow-white">{obj[n]}&emsp;</span>
): <span>None</span>

const PillDsList = ({list, dataKey, styleName, bgColor, locale}) => list && list.length>0 ? list.map((n, i) =>
  <Fragment key={i}>
    <div className={styleName} style={{backgroundColor: (bgColor || n.data.bg_color)}}>
      {getLocale(n.data, locale, dataKey)}&emsp;
    </div>
  </Fragment>
): <span>None</span>

const PillDsField = ({field, dataKey, styleName}) => field ?
  <div style={{display: 'inherit'}} className={styleName}>{field[dataKey] && field[dataKey]}</div>: <div>None</div>

const PillTagList = ({list, styleName}) => list && list.length>0 ? list.map((n, i) =>
  <div key={i} className={styleName}>{n}&emsp;</div>
): <span>None</span>

const DraftBadge = ({isDraft, styleName}) => isDraft? <span className={`badge-2 white bg-draft-2 ${styleName?styleName:''}`}>Draft</span>:null;

const GrowthSpanField = ({data, note, unit}) => {
  let className;
   if(note === "above_median")
     className = "bg-submit";
   else if(note === "below_median")
     className = "bg-cancel";
   else
     className ="bg-safforn";
  return <span className={className + " pill p-5 white"}>{data && data + " " + unit}</span>
}

export {Badge, PillList, DraftBadge, PillTagList, NotificationList, PillDsList, PillDsField, GrowthSpanField};