import React,{Fragment} from "react";
import {Image, ImageList} from "./Image";
import FormHelpers from '../FormBuilder/FormHelpers';
import {AudioPlayer} from './RecordAudio';
import {FormSubmissionReview} from '../Reviews/FormSubmissionReview';
import {PillDsList, PillList} from './NotificationPill';
import { DivRichTextView, ParaRichTextView } from "./RichTextView";
import Rating from '../Common/Rating';
import {getLocale} from '../FormBuilder/FieldLabel';

const RenderData = ({data={}, ds={}, dataKey, onDataItemSelect, field={}, locale}) =>{
  const props = {};
  if(onDataItemSelect) props.onClick = () => onDataItemSelect(dataKey);

  let d = ds?.[dataKey];
  if(d){
    if(Array.isArray(d)){
      return (
        <div className="flex wrap gap-5">
          <PillDsList list={d} dataKey='label' 
            bgColor={field.icon_color}
            locale={locale}
            styleName={"badge white mxw-150 m-r-5 m-b-5 " + (field.icon_color ? '' : 'bg-highlight')}/>
        </div>
      )
    }else{
      return(
        <div className="flex wrap gap-5">
          {d.bg_color 
            ? <span className="pill white" style={{backgroundColor:d.bg_color}}>{getLocale(d, locale)}</span>
            : getLocale(d, locale)
          }
        </div>
      )
    }
  }else if(Array.isArray(data[dataKey+'_array'])){
    return (
      <div className="flex wrap gap-5">
        <PillList list={data[dataKey+'_array']}
          onSelect={onDataItemSelect}
          dataKey='name'style="badge white bg-highlight m-r-5 m-b-5"/>
      </div>
    )
  }else if(data[dataKey]){
    return(
      <div {...props}>{data[dataKey]}</div>
    )
  }

  return null; 
}

const FormFieldTable = ({formFields = [], data = {}, dataSource = {}, onDataItemSelect}) => formFields.map((f, i)=>{
  if(FormHelpers.checkFieldConditionality(f, data)){
    if(f.component == 'richtext'){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <DivRichTextView html={data?.[f.client_id]}/>
        </div>
      )
    }else if('imageupload'.includes(f.component)){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <Image image={data?.[f.client_id]} styleName="w-70p"/>
        </div>
      )
    }else if('multi_file_upload'.includes(f.component)){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <ImageList list={data?.[f.client_id]} styleName="w-70p"/>
        </div>
      )
    }else if(f.component === 'rich_image_map'){
      return null;
    }else if(f.component === 'lottie_svg'){
      return null;
    }

    return( 
      <div key={i} className="border-dashed-sm p-b-15 m-b-15">
        <h5 className="fw500 m-b-5">{f.label}</h5>
        <RenderData data={data} ds={dataSource} field={f} 
        dataKey={f.client_id} onDataItemSelect={onDataItemSelect}/>
      </div>
    )
  }
    
  return null;
})

const TableColumnData = ({formFields = [], formFieldViewEle={}, data = {}, dataSource = {}, currentLocale}) => formFields.map((f, i)=>{
  if(f.component === 'richtext'){
    return(
      <td className="text-center" key={i}>
        <DivRichTextView className="pre-wrap mxh-100 scroll-x" html={data?.[f.client_id]}/>
      </td>
    )
  }else if(f.component === 'imageupload'){
    return(
      <td className="text-center" key={i}>
        <Image image={data?.[f.client_id]} styleName="w-70p"/>
      </td>
    )
  }else if(f.component === 'multi_file_upload'){
    return(
      <td className="text-center" key={i}>
        <ImageList list={data?.[f.client_id]} styleName="w-70p"/>
      </td>
    )
  }else if(f.component === 'rich_image_map'){
    return null
  }else if(f.component === 'lottie_svg'){
    return null
  }else if(f.component === 'rating'){
    return(
      <td className="text-center" key={i}>
        <Rating sumOfRating={data?.[f.client_id]}/>
      </td>
    )
  }else if(formFieldViewEle[f.client_id]){
    return(
      <td className="text-center" key={i}>
        {formFieldViewEle[f.client_id](f.client_id, data, dataSource)}
      </td>
    )
  }

  return( 
    <td className="text-center" key={i}>
      <RenderData data={data} ds={dataSource} dataKey={f.client_id} field={f} locale={currentLocale}/>
    </td>
  )
})

const FormFieldCard = ({formFields = [], data = {}, dataSource = {}, onDataItemSelect, formReviewProps = {}, formFieldViewEle = {}, currentLocale}) => formFields.map((f, i) => {
  if (FormHelpers.checkFieldConditionality(f, data)) {
    const { reviewByClientId, isReviewEnabled } = formReviewProps;
    const localizedLabel = getLocale(f, currentLocale); 

    if(f.component === 'explanatory_text'){
      return null;
    }else if (f.component === 'richtext') {
      return (
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{localizedLabel}</h5>
          <ParaRichTextView html={data?.[f.client_id]}/>
          {isReviewEnabled ? (
            <FormSubmissionReview 
              formId={f.id} 
              fieldClientId={f.client_id}
              fieldIndex={f.index} 
              editReview={reviewByClientId[f.client_id]}
              {...formReviewProps}/>
            ) : null
          }
        </div>
      )
    } else if (f.component === 'imageupload') {
      return (
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{localizedLabel}</h5>
          <Image image={data?.[f.client_id]} styleName="w-85 w-uh" />
          {isReviewEnabled ? (
            <FormSubmissionReview 
              formId={f.id} 
              fieldClientId={f.client_id}
              fieldIndex={f.index}
              {...formReviewProps}
              editReview={reviewByClientId[f.client_id]}/>
            ) : null
          }
        </div>
      )
    } else if (f.component === 'multi_file_upload') {
      return (
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{localizedLabel}</h5>
          <ImageList list={data?.[f.client_id]} styleName="w-85 w-uh" />
          {isReviewEnabled ? (
            <FormSubmissionReview 
              formId={f.id} 
              fieldClientId={f.client_id}
              fieldIndex={f.index} 
              {...formReviewProps}
              editReview={reviewByClientId[f.client_id]}/>
          ) : null}
        </div>
      )
    } else if (f.component === 'record_audio') {
      return (
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{localizedLabel}</h5>
          <AudioPlayer url={data?.[f.client_id]} />
          {isReviewEnabled ? (
            <FormSubmissionReview 
              formId={f.id} 
              fieldClientId={f.client_id}
              fieldIndex={f.index} 
              {...formReviewProps}
              editReview={reviewByClientId[f.client_id]}/>
          ) : null}
        </div>
      )
    } else if (f.component === 'table_view') {
      return (
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{localizedLabel}</h5>
          <div className="table-responsive tab-with-table p-0">
            <table className="table table-stripe table-head tl-fix w-full">
              <thead>
                <tr>
                  <td></td>
                  {f ? f.columns.map((c, i) => <td key={i}>{c.label}</td>) : null}
                </tr>
              </thead>
              <tbody>
                <InputTableViewRows 
                  data={data} 
                  dataSource={dataSource} 
                  rowOptions={f.options} 
                  columns={f.columns}/>
              </tbody>
            </table>
          </div>
        </div>
      )
    } else if (f.component === 'rich_image_map' || f.component === 'lottie_svg') {
      return null;
    } else if (formFieldViewEle[f.client_id]) {
      return (
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{localizedLabel}</h5>
          {formFieldViewEle[f.client_id](f.client_id, data, dataSource)}
        </div>
      )
    } else if (f.component === 'rating') {
      return (
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{localizedLabel}</h5>
          <Rating sumOfRating={data?.[f.client_id]} />
        </div>
      )
    }

    return (
      <div key={i} className="border-dashed-sm p-b-15 m-b-15">
        <h5 className="fw500 m-b-5">{localizedLabel}</h5>
        <RenderData 
          data={data} 
          ds={dataSource} 
          dataKey={f.client_id} 
          onDataItemSelect={onDataItemSelect}
          locale={currentLocale} 
          field={f}/>
        {isReviewEnabled ? (
          <FormSubmissionReview 
            formId={f.id} 
            fieldClientId={f.client_id}
            fieldIndex={f.index} 
            editReview={reviewByClientId[f.client_id]}
            {...formReviewProps}/>
        ) : null}
      </div>
    )
  }
  return null;
})

const InputTableViewRows = ({rowOptions, columns, data, dataSource}) => rowOptions.map((r, k)=>{
  return(
    <tr key={k}>  
      <td className="font-15">
        {r.label} 
      </td>
      {columns.map((c, i)=> 
        <td key={i}>
          <RenderData data={data} ds={dataSource} dataKey={r.sid +'_'+ c.client_id}/>
        </td>
      )}
    </tr>
  )
})

export {RenderData, PillList, FormFieldCard, TableColumnData, FormFieldTable};