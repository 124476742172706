import React, { Fragment, useState, useEffect, useContext, useRef, useMemo } from "react"
import OrganizationMemberService from "./OrganizationMemberService"
import GenericModal from '../../Modals/GenericModal'
import { useHistory } from "react-router-dom"
import { CueTip } from "../../Common/CueTooltip"
import useModal from "../../../hooks/useModal"
import { MemberGeoDataModal } from "./MemberGeoDataModal"
import { ECharts } from "../../Charts/ECharts"
import { Filters } from "../../HealthJourney/MotherInfantJourney/Charts/Filters"
import FilterSlider from "../../Common/FilterSlider"
import { OrgMemberCharts } from "../../../constants/DataSourceList"
import CommentsModal from "../../Modals/CommentsModal"
import ChecklistsModal from "../../Modals/ChecklistsModal"
import { ChatGrayIcon, CheckListGrayIcon } from "../../Common/ImageIcons"
import { FontAwesomeBtn } from "../../Common/Button"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { usePageLocale } from '../../../hooks/useLocale'
import AddCaseAssessmentIco from "../../HealthJourney/CaseAssessments/AddCaseAssessmentIco"
import McjOrgMemberDailyReportService from "../McjOrgMemberDailyReportService"
import Cs from "../../../services/CommonService"
import { useActivityHook } from "../../HealthJourney/MotherInfantJourney/Activities/List/useActivityHook"
import { useCurrentUserHook } from "../../Authentication/useUserHook"
import { Spinner } from "../../Common/Spinner"
import CheckAccess from '../../Roles/CheckAccess'
import Rating from "../../Common/Rating"
// let selectedChart = null

const ChartReports = (props) => {
  let { current: scope } = useRef({ selectedChart: null });
  const {currentUser, getCurrentOrgName} = useCurrentUserHook();
  const {organizationId, isFilterOpen, toggleFilter, activeTab, chartsColors} = props;
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    'report_type':OrgMemberCharts[0].sid
  });
  const [data, setData] = useState([]);
  const chartId = "chart_container";
  const [reportType, setReportType] = useState();
  const [legend, setLegend] = useState({show:false});

  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal } = useModal();
  const {isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal();

  useEffect(()=>{
    let report = OrgMemberCharts.find(c => c.sid === filter.report_type);
    if(report){
      const titles = report.chart.title.text?.split('-');
      report.chart.title.text = titles[0] + (" - " + getCurrentOrgName()); //Set Org name
      /*const sub_titles = report.chart.title.sub_text?.split('-');
      report.chart.title.sub_text = sub_titles[0] + (" - " + Cs.formatUpdateDate());*/
    }
    setReportType(report);
    if(filter.report_type !== "geo_location"){
      getChartReport(filter);
    }
  }, [filter])

  const filterPermission = useMemo(() =>CheckAccess.destructUser(currentUser).permissions?.mcj_filter, [currentUser.id]);

  const getChartReport = async (filter) =>{
    setLoading(true);
    let req = {
      'start_date':filter.start_date,
      'end_date':filter.end_date,
      'organization_id':organizationId,
      'report_type':filter.report_type,
    };
    /*if(filterPermission?.trainee_batch){
      req.filter = {...filter, is_f2f_trainee_user: true};
    }*/
    const {status, data} = await OrganizationMemberService.getChartReport(req);
    if(status === 200){
      setData(data.chart_data);
      setLoading(false);
    }
  }

  const openNotes = (type, callback) =>{
    scope.selectedChart = {
      id:currentUser.current_organization_id,
      type:type
    }
    
    if(callback === 'comment'){
      toggleCommentModal()
    }else if(callback === 'checklist'){
      toggleChecklistModal()
    }
  }

  if(loading)
    return <Spinner/>

  return (
    <>
      {/*<div className="p-5 bg-white clearfix">
        <select className="form-control p-5 w-250 m-t-5 pull-right" value={reportType.sid} onChange={e=>setReportType(OrgMemberCharts[e.target.selectedIndex-1])}>
          <option>None</option>
          <OptionsObjList list={OrgMemberCharts} id_key="sid" label_key="label" />
        </select>
      </div>*/}
      <div id="ct">
        <div className="bg-white brd-10 card-shadow m-b-15">
          <div className="card-title card-title-gray flex p-b-15 p20 coldir"></div>
          <div className="wk-center bg-white w-full brd-10">
          {reportType?.sid === 'org_member_ranking' ?
            <div className="table-responsive">
              <OrgMemberRanking rankings={data} />
            </div>
            :
            <>
              {reportType?.sid === 'geo_location'?
                <div className="clearfix">
                  <MemberGeoDataModal filter={filter} organizationId={organizationId}/>
                </div>
                :
                <div className="col-xs-12">
                  <ECharts chartId={chartId} 
                  chartType={reportType?.type}
                  data={data || [{}]} 
                  {...(reportType?.chart||{})} 
                  legend={legend}
                  colors={chartsColors}/>
                </div>
              }
            </>
          }
          
          <div className="card-footer flex justspacebetween p20 aligncenter">
            <ul className="flex aligncenter">
              <li className="m-r-25" data-tip="Chat">
                <ChatGrayIcon onSelect={()=>openNotes(reportType?.sid+'_chart', 'comment')}/>
              </li>
              <li className="m-r-25" data-tip="Checklist">
                <CheckListGrayIcon onSelect={()=>openNotes(reportType?.sid+'_chart', 'checklist')}/>
              </li>
            </ul>
            {reportType?.type === 'pie' ?
              <button className="btn bg-grey m-r-10" onClick={()=>setLegend({show:!legend.show})}>
                Legends
              </button>
              : null
            }
            </div>
          </div>
        </div>
      </div>
      
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
          toggleModal={toggleChecklistModal} 
          item={scope.selectedChart} 
          type={scope.selectedChart.type}/>
      }
      
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
          toggleModal={toggleCommentModal} 
          item={scope.selectedChart} 
          type={scope.selectedChart.type}/>
      }

      {isFilterOpen && activeTab === 'org_members' &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={()=>toggleFilter()}
          chartFilter={filter}
          setChartFilter={(f)=>setFilter(f)}
          activeTab={activeTab}/>
      }
    </>
  )  
}

const ChartBtn = (props) =>{
  const { isOpen: isChartOpen, toggleModal: toggleChartOpen} = useModal();

  return(
    <>
      <span id="files_upload" onClick={() => toggleChartOpen()} 
        className="bg-highlight round-btn float-right m-r-5 tooltip"> 
          <i className="far fa-chart-bar" />
          <CueTip tooltip="Chart" positionCss="bottom"/>
      </span>

      {isChartOpen &&
        <GenericModal component={ChartReports} 
          isOpen={isChartOpen} title="Report"
          toggleModal={toggleChartOpen}
          widthCss="w-90p"
          {...props}/>
      }
    </>
  )
}

const OrgMemberRanking = ({rankings}) =>{
  const [list, setList] = useState(rankings);
  const [order, setOrder] = useState('asc');

  //users.sort((a, b) => a.firstname.localeCompare(b.firstname))

  function sort(column) {
    const newOrder = order === "asc" ? "desc" : "asc";
    if (newOrder === "asc") {
      list.sort((a, b) => a[column] - b[column]);
    } else {
      list.sort((a, b) => b[column] - a[column]);
    }
    setOrder(newOrder);
  }

  return(
    <Table className="shadow-small">
      <THead>
        <TR>
          <TH onClick={()=>sort('name')}>
            Name 
          </TH>
          <TH onClick={()=>sort('total')}>
            Total 
          </TH>
          <TH onClick={()=>sort('journey_count')}>
            Journey 
          </TH>
          <TH onClick={()=>sort('anc_count')}>
            ANC 
          </TH>
          <TH onClick={()=>sort('schedule_complete_count')}>
            Schedule Complete 
          </TH>
          <TH onClick={()=>sort('hb_value_diff')}>
            HB Value CHG 
          </TH>
          <TH onClick={()=>sort('total_protein_diff')}>
            Total Protein CHG 
          </TH>
          <TH onClick={()=>sort('baby_weight_diff')}>
            Baby Weight CHG 
          </TH>
        </TR>
      </THead>
      <TBody>
        <TableData rankings={rankings}/>
      </TBody>
    </Table>
  )
}

const TableData = ({rankings}) => rankings.map((r, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {r.name}
        </TD>
        <TD className="text-gray">
          {r.total?.toFixed(2)}
        </TD>
        <TD className="text-gray">
          {r.journey_count}
        </TD>
        <TD className="text-gray">
          {r.anc_count}
        </TD>
        <TD className="text-gray">
          {r.schedule_complete_count}
        </TD>
        <TD className="text-gray">
          {r.hb_value_diff}
        </TD>
        <TD className="text-gray">
          {r.total_protein_diff}
        </TD>
        <TD className="text-gray">
          {r.baby_weight_diff?.toFixed(3)}
        </TD>
      </TR>
    )
})

const MCJGroupByPregnancyStage = ({userId, organizationId}) =>{
  const { state:{user:currentUser}} = useContext(AuthContext)
  const { labelLocale } = usePageLocale(currentUser.current_locale, 'userInfoLabels')

  const [data, setData] = useState([])
  const {current:scope} = useRef({})

  useEffect(()=>{
    getChartData()
  }, [])
  
  const getChartData = async () =>{
    const {status, data:{chart_data:{case_pregnancy_stage, case_status, created_at}}} = await OrganizationMemberService.getChartReport({
      'organization_id':organizationId,
      'report_type':'mcj_case_pregnancy_stage',
      'user_id':userId
    })
    
    const halfPieData = {
      'value': case_pregnancy_stage.reduce(function(acc, val) { return acc + parseInt(val.value || 0); }, 0),
      'itemStyle': {
        // stop the chart from rendering this piece
        'color': 'none',
        'decal': {
          'symbol': 'none'
        }
      },
      'label': {
        'show': false
      }
    }
    scope.total = case_pregnancy_stage.map(d=>d.value?parseInt(d.value):0).reduce((a, b) => a + b, 0);
    scope.case_status = case_status;
    scope.created_at = created_at;
    setData([...case_pregnancy_stage, halfPieData]);
  }

  // make an record to fill the bottom 50%
  
  if(data.length > 0){
    return (
      <div className="row">
        <div className="col-md-12">
          {scope.total > 0 ?
            <div className="br-grey wk-center clearfix">
              <ECharts chartId="chart_1" 
                data={data} 
                chartType="pie-half-donut"
                legend={{show: true}}
                formatter={(p)=>`${p.data.name} - ${p.data.value}`}
                graphic = {{
                  elements: [
                    {
                      type: 'text',
                      left: 'center',
                      bottom: '25%',
                      z: 999,
                      style: {
                        text: `Total ${scope.total}`,
                        textAlign: 'center',
                        fontSize: 20,
                      }
                    },
                    {
                      type: 'text',
                      left: 'center',
                      bottom: '20%',
                      z: 999,
                      style: {
                        text: "Case type at registration",
                        textAlign: 'center',
                        fontSize: 16,
                      }
                    }
                  ]
                }}
                height="500px"/>
              <div className="flex justspacebetween">
                <div className="flex gap-5 f15 m-l-15">
                  <span className="underline">Active {scope.case_status.case_active}</span>
                  <span className="underline">Inactive {scope.case_status.case_inactive}</span>
                  <span className="underline">Closed {scope.case_status.case_closed}</span>
                  <span className="underline">Deleted {scope.case_status.case_deleted}</span>
                  <AddCaseAssessmentIco
                    className="pull-right m-b-10 m-l-5 highlight" assessUserId={userId}
                    assessmentData={{
                      'assessment_type': 'Chart',
                      'assessment_type_sid': 'case_pregnancy_stage'
                    }}>
                    <span className="far fa-list-alt font-24"/>
                  </AddCaseAssessmentIco>
                </div>
                <div className="f14 m-r-15 text-lite-gray">
                  Last updated at {Cs.formatUpdateDate(scope.created_at, 'MMM D, YYYY hh:mm a')}.
                </div>
              </div>
            </div>
            :
            <h4 className="text-center" data-label="No Cases">{labelLocale(1)}</h4>
          }
        </div>
      </div>
    )
  }

  return null;
}

const MCJCaseScheduleAdherence = ({userId, organizationId}) =>{
  const { state:{user:currentUser}} = useContext(AuthContext);
  const { labelLocale } = usePageLocale(currentUser.current_locale, 'userInfoLabels');

  const [list, setList] = useState([]);
  const [order, setOrder] = useState('asc');
  const {current:scope} = useRef({
    'colorIdx':{
      '1':{'color':'green', 'icon':'far fa-calendar-check'}, 
      '2':{'color':'red', 'icon':'far fa-calendar-times'}, 
      '3':{'color':'#B561F8', 'icon':'far fa-calendar-check'}
    }
  });

  const history = useHistory();

  useEffect(()=>{
    getChartData();
  }, [])
  
  const getChartData = async () =>{
    const {status, data:{chart_data}} = await OrganizationMemberService.getChartReport({
      'organization_id':organizationId,
      'report_type':'mcj_case_schedule_adherence',
      'user_id':userId
    })

    scope.days = [];
    for (let i = 0; i < 100; i++) {
      scope.days.push(i);
    }

    setList([...chart_data]);
  }

  const routeJourney = (journeyProfileId)=>{
    history.push({
      pathname:`/health/common/page/${journeyProfileId}`,
      search:"?type=infant_journey" 
    })
  }

  function sort(column) {
    const newOrder = order === "asc" ? "desc" : "asc";
    if (newOrder === "asc") {
      list.sort((a, b) => a[column] - b[column]);
    } else {
      list.sort((a, b) => b[column] - a[column]);
    }
    setOrder(newOrder);
  }

  if(list.length > 0){
    return(
      <div id="ct">
        <div className="flex justspacebetween p-l-10 p-r-5">
          <div>
            <div className="bold-600 f16">Adherence to protocol schedule</div>
            <div>(For cases with age &lt; 6 months)</div>
          </div>
          <div className="flex">
            <div className="m-r-15">
              <i style={{'color' : scope.colorIdx[1].color}} className={`${scope.colorIdx[1].icon} f20 m-r-5`}/> Visited
            </div>
            <div className="m-r-15">
              <i style={{'color' : scope.colorIdx[2].color}} className={`${scope.colorIdx[2].icon} f20 m-r-5`}/> Missed
            </div>
            <div className="m-r-15">
              <i style={{'color' : scope.colorIdx[3].color}} className={`${scope.colorIdx[3].icon} f20 m-r-5`}/> Custom Visit
            </div>
          </div>
        </div>
        <div className="table-fix-head">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={()=>sort('case_name')}>
                  Name 
                </TH>
                <TH>
                  Assessment 
                </TH>
                {scope.days.map((d, i)=>
                  <TH key={i} className="f15">
                    {labelLocale(2)} {d}
                  </TH>
                )}
              </TR>
            </THead>
            <TBody>
              <MCJCaseScheduleAdherenceTableData list={list} userId={userId} days={scope.days} colorIdx={scope.colorIdx} routeJourney={routeJourney}/>
            </TBody>
          </Table>
        </div>
      </div>
    )
  }

  return null;
}

const MCJCaseTimeline = ({ userId, organizationId, mcjComponentList }) => {
  const { state:{user:currentUser}} = useContext(AuthContext);
  const { getObjLocale } = usePageLocale(currentUser.current_locale);

  const [list, setList] = useState([]);
  const [order, setOrder] = useState('asc');

  const history = useHistory();
  const {current:scope} = useRef({'mcjComponentList':[]});

  useEffect(()=>{
    getChartData();

    return () => scope.handler && clearTimeout(scope.handler);
  }, [])
  
  const getChartData = async () =>{
    const {status, data:{chart_data:{mcj_components, case_timeline}}} = await OrganizationMemberService.getChartReport({
      'organization_id':organizationId,
      'report_type':'mcj_case_timeline',
      'user_id':userId
    })
    const mcjComponentList = [...mcj_components.element_ppt_mcj.options , ...mcj_components.pins_process_mcj.options];
    scope.mcjComponentMap = mcjComponentList.reduce((obj, item) => (obj[item.sid] = item.short_code, obj), {});
    setList(case_timeline);
  }

  function sort(column) {
    const newOrder = order === "asc" ? "desc" : "asc";
    if (newOrder === "asc") {
      list.sort((a, b) => a[column] - b[column]);
    } else {
      list.sort((a, b) => b[column] - a[column]);
    }
    setOrder(newOrder);
  }

  const routeJourney = (journeyProfileId)=>{
    history.push('/offline'); //temp state

    setTimeout(() => {
      history.push({
        pathname:`/health/common/page/${journeyProfileId}`,
        search:"?type=infant_journey" 
      })
    }, 10);
  }

  if(list.length > 0){
    return(
      <div id="ct">
        <div className="table-fix-head">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={()=>sort('case_name')}>
                  Name 
                </TH>
                <TH>
                  Assessment
                </TH>
                <TH>
                  Timeline
                </TH>
              </TR>
            </THead>
            <TBody>
              {list.map((r, k) => (
                <TR key={k}>
                  <TD onSelect={()=>routeJourney(r.journey_profile_id)}>{r.case_name}</TD>
                  <TD>
                    <AddCaseAssessmentIco className="m-l-15 highlight" assessUserId={userId} 
                      journeyProfileId={r.journey_profile_id}
                      assessmentData={{
                        'assessment_type':'Case Timeline', 
                        'assessment_type_sid':'case_timeline',
                      }}>
                        <span className="far fa-list-alt font-18"/>
                    </AddCaseAssessmentIco>
                    <Rating sumOfRating={r.case_assessment?.rate_case_worker}/>
                  </TD>
                  <TD>
                    <div className="flex m-t-10 aligncenter">
                      <div className="badge-3 w-40 ws-bs ws-unset f10-xss text-upp">
                        <div>{scope.mcjComponentMap.journey_profile}</div>
                        <div className="text-lite-gray m-t-5">{Cs.formatUpdateDate(r.created_at)}</div>
                      </div>
                      {r.timeline.length > 0 ? <i className="gg-arrow-long-right"/> : null }
                      <CaseTimeline mcjComponentMap={scope.mcjComponentMap} caseTimeline={r.timeline}/>
                    </div>
                  </TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </div>
      </div>
    )
  }

  return null;
}

const CaseTimeline = ({mcjComponentMap, caseTimeline}) => caseTimeline.map((t, i)=>{
  let labelEle = mcjComponentMap[t.t_sid];
  if(!labelEle){
    if(t.t_sid === 'case_report')labelEle = <span className="fas fa-paperclip"/>;
  }

  return(
    <Fragment key={i}>
      <div className="badge-3 w-40 ws-bs ws-unset f10-xss text-upp">
        <div>{labelEle}</div>
        <div className="text-lite-gray m-t-5">{Cs.formatUpdateDate(t.date)}</div>
      </div>
      {i+1 < caseTimeline.length ? <i className="gg-arrow-long-right"/> : null }
    </Fragment>
  )
})

const MCJCaseScheduleAdherenceTableData = ({list, days, routeJourney, colorIdx, userId}) => list.map((r, k) => {
  return(
    <TR key={k}>
      <TD className="text-gray fw500" onSelect={()=>routeJourney(r.id)}>
        {r.case_name}
      </TD>
      <TD>
        <AddCaseAssessmentIco className="m-l-15 highlight" assessUserId={userId} 
          journeyProfileId={r.id}
          assessmentData={{
            'assessment_type':'Case Schedule Adherence', 
            'assessment_type_sid':'case_schedule_adherence'
          }}>
            <span className="far fa-list-alt font-18"/>
        </AddCaseAssessmentIco>
      </TD>
      {days.map((d, i)=>
        <TD key={i}>
          {colorIdx[r.days?.[d]] ? 
            <i className={`${colorIdx[r.days[d]].icon} f20`} style={{'color' : colorIdx[r.days[d]].color}}/>
            :
            null
          }
        </TD>
      )}
    </TR>
  )
})

const CaseActivityCountTable = ({userId, organizationId, subscriptionId}) =>{
  const { state:{user:currentUser}} = useContext(AuthContext);
  const { getObjLocale } = usePageLocale(currentUser.current_locale);

  const [list, setList] = useState([]);
  const [order, setOrder] = useState('asc');

  const history = useHistory();
  const {current:scope} = useRef({'mcjComponentList':[]})

  useEffect(()=>{
    getChartData();
  }, [])
  
  const getChartData = async () =>{
    const {status, data:{chart_data:{mcj_components, case_activity_count}}} = await OrganizationMemberService.getChartReport({
      'organization_id':organizationId,
      'subscription_id':subscriptionId,
      'report_type':'mcj_case_activity_count',
      'user_id':userId
    });
    scope.mcjComponentList = [...mcj_components.element_ppt_mcj.options , ...mcj_components.pins_process_mcj.options];
    setList(case_activity_count);
  }

  function sort(column) {
    const newOrder = order === "asc" ? "desc" : "asc";
    if (newOrder === "asc") {
      list.sort((a, b) => a[column] - b[column]);
    } else {
      list.sort((a, b) => b[column] - a[column]);
    }
    setOrder(newOrder);
  }

  const routeJourney = (journeyProfileId)=>{
    history.push({
      pathname:`/health/common/page/${journeyProfileId}`,
      search:"?type=infant_journey" 
    })
  }

  if(list.length > 0){
    return(
      <div id="ct">
        <div className="table-fix-head">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={()=>sort('case_name')}>
                  Name 
                </TH>
                <TH>
                  Assessment
                </TH>
                {scope.mcjComponentList.map((c, i)=>
                  <TH key={i} onClick={()=>sort(c.sid)} className="f15">
                    {getObjLocale(c)} 
                  </TH>
                )}
              </TR>
            </THead>
            <TBody>
              <CaseActivityCountTableData list={list} userId={userId} mcjComponentList={scope.mcjComponentList} routeJourney={routeJourney}/>
            </TBody>
          </Table>
        </div>
      </div>
    )
  }

  return null;
}

const CaseActivityCountTableData = ({list, userId, mcjComponentList, routeJourney}) => list.map((r, k) => {
  return(
    <TR key={k}>
      <TD className="text-gray fw500" onSelect={()=>routeJourney(r.id)}>
        {r.case_name}
      </TD>
      <TD>
        <AddCaseAssessmentIco className="m-l-15 highlight" assessUserId={userId} 
        journeyProfileId={r.id}
        assessmentData={{
          'assessment_type':'Case Activity Count', 
          'assessment_type_sid':'case_activity_count',
        }}>
          <span className="far fa-list-alt font-18"/>
        </AddCaseAssessmentIco>
      </TD>
      {mcjComponentList.map((c, i)=>
        <TD key={i} className="text-gray">
          {r[c.sid] || 0} 
        </TD>
      )}
    </TR>
  )
})

const AssessCFChart = ({ activityId, formId, organizationId, journeyProfileId }) => {
  const { state: { user: currentUser } } = useContext(AuthContext)
  const [data, setData] = useState([])

  useEffect(() => {
    getChartData()
  }, [])
  
  const getChartData = async () => {
    const { status, data: { chart_data } } = await OrganizationMemberService.getChartReport({
      "activity_id": activityId,
      'report_type': 'mcj_access_cf',
      'journey_profile_id': journeyProfileId,
      'organization_id': organizationId,
      'locale': currentUser.current_locale,
      'form_id': formId
    })
    setData(chart_data || [])
  }

  const legend = {
    show: true,
    /* data: data.map(d=>d.name),
    top: 55,
    itemGap: 5,
    backgroundColor: 'rgb(243,243,243)',
    borderRadius: 5*/
  }

  const title = {
    text: 'Number of days food given to baby in previous week',
    left: 'center',
    top: '20px',
    textStyle: {
      fontSize: 20,
      fontWeight: 'bold'
    }
  }

  return (
    <div className="pos-rel text-center">
      {data && data.length > 0 ? (
        <ECharts
          chartId="mcj_access_cf"
          chartType="treemap"
          data={data}
          formatter={(p) => `${p.data.name}-${p.data.value}`}
          legend={legend}
          label={{ show: true, fontSize: 17 }}
          labelFormatter={p => `${p.data.name}-${p.data.value}`}
          title={title}
        />
      ) : (
        <div className="f15 bold">No data to display</div>
      )}
    </div>
  )
}

const AssessCfChartBtn = (props) =>{
  const { isOpen, toggleModal } = useModal()
  return isOpen ?
    <GenericModal title="Assess CF" {...props} component={AssessCFChart} isOpen={isOpen} toggleModal={toggleModal} />
    :
    <FontAwesomeBtn id="cf_charts" tooltip="AssessCF Chart" 
      iconCss="fa fas fa-chart-line" 
      isResponsive={false} name={props.name || "AccessCF Chart"}
      css="pull-right m-b-5 m-r-15 lh-17" 
      colorCss="bg-highlight"
      onSelect={()=>toggleModal()}>
    </FontAwesomeBtn>
}

const ProteinIntakeChart = ({ activityId, formId, organizationId, journeyProfileId }) => {
  const { state: { user: currentUser } } = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getChartData();
  }, [])
  const getChartData = async () => {
    const { status, data: { chart_data } } = await OrganizationMemberService.getChartReport({
      "activity_id": activityId,
      'report_type': 'protein_intake',
      'journey_profile_id': journeyProfileId,
      'organization_id': organizationId,
      'locale': currentUser.current_locale,
      'form_id': formId
    })
    setData(chart_data || [])
  }

  const legend = {
    show: true
  }

  const title = {
    text: 'Protein sources consumed by mother',
    left: 'center',
    top: '20px',
    textStyle: {
      fontSize: 20,
      fontWeight: 'bold'
    }
  };

  return (
    <div className="pos-rel text-center">
      {data && data.length > 0 ? (
        <ECharts
          chartId="protein_intake"
          chartType="treemap"
          data={data}
          formatter={(p) => `${p.data.name}-${p.data.value}`}
          legend={legend}
          label={{ show: true, fontSize: 17 }}
          labelFormatter={p => `${p.data.name}-${p.data.value}`}
          title={title} 
        />
      ) : (
        <div className="f20 bold">No data to display</div>
      )}
    </div>
  )
}

const ProteinIntakeChartBtn = (props) =>{
  const { isOpen, toggleModal } = useModal();
  return isOpen ?
    <GenericModal title="Assess BF" {...props} component={ProteinIntakeChart} isOpen={isOpen} toggleModal={toggleModal} />
    :
    <FontAwesomeBtn id="_charts" tooltip="ProteinIntake Chart" 
      iconCss="fa fas fa-chart-line" 
      isResponsive={false} name={props.name || "ProteinIntake Chart"}
      css="pull-right m-b-5 m-r-15 lh-17" 
      colorCss="bg-highlight"
      onSelect={()=>toggleModal()}>
    </FontAwesomeBtn>
}

const MeasureGrowthZscoreChartByCase = ({organizationId, userId}) =>{
  const [list, setList] = useState([])
  const history = useHistory()

  useEffect(()=>{
    getChartData()
  }, [])
  
  const getChartData = async () =>{
    const {status, data:{chart_data}} = await OrganizationMemberService.getChartReport({
      'report_type':'mcj_measure_growth_zscore',
      'organization_id':organizationId,
      'user_id':userId
    })
    setList(chart_data)
  }

  const routeJourney = (journeyProfileId)=>{
    history.push({
      pathname:`/health/common/page/${journeyProfileId}`,
      search:"?type=infant_journey" 
    })
  }

  if(list.length > 0){
    return(
      <div id="ct">
        <div className="p-l-10 p-r-5">
          <div className="bold-600 f16">Z score trends for PNC cases</div>
        </div>
        <div className="table-fix-head">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH>
                  Name 
                </TH>
                <TH>
                  Assessment
                </TH>
                <TH>
                  Z score (W) 
                </TH>
                <TH>
                  Z score (L)  
                </TH>
                <TH>
                  Z score (W/L)  
                </TH>
              </TR>
            </THead>
            <TBody>
              <MeasureGrowthZscoreTableBody list={list} userId={userId} routeJourney={routeJourney}/>
            </TBody>
          </Table>
        </div>
      </div>
    )
  }else{
    return null
  }
}

const MeasureGrowthZscoreTableBody = ({list, routeJourney, userId}) => list.map((r, k) => {
  return(
    <TR key={k}>
      <TD className="text-gray fw500" onSelect={()=>routeJourney(r.id)}>
        {r.case_name}
      </TD>
      <TD>
        <AddCaseAssessmentIco className="m-l-15 highlight" assessUserId={userId} 
          journeyProfileId={r.id}
          assessmentData={{
            'assessment_type':'Zscore', 
            'assessment_type_sid':'case_activity_zscore',
          }}>
            <span className="far fa-list-alt font-18"/>
        </AddCaseAssessmentIco>
        <Rating></Rating>
      </TD>
      <TD>
        <MeasureGrowthZscoreChart chartId={`wz_${k}`} zscoreList={r.weight_zscore_list || []} yAxisLabel="Z score (W)"/>
      </TD>
      <TD>
        <MeasureGrowthZscoreChart chartId={`hz_${k}`} zscoreList={r.height_zscore_list || []} yAxisLabel="Z score (L)"/>
      </TD>
      <TD>
        <MeasureGrowthZscoreChart chartId={`wfh_${k}`} zscoreList={r.wfh_zscore_list || []} yAxisLabel="Z score (W/L)"/>
      </TD>
    </TR>
  )
})

const MeasureGrowthZscoreChart = ({chartId, zscoreList, yAxisLabel}) =>{
  const option = {
    legend: {},
    tooltip: {
      trigger: 'axis',
      showContent: false
    },
    xAxis: {name:'Baby age (days)', nameLocation:'middle', nameGap:25},
    yAxis: {name: yAxisLabel || 'Z score', nameLocation:'middle', nameGap:20, gridIndex: 0, type: 'value', min:-3, max:3, minInterval: 0.5},
    grid: { left: '40px' },
    dataset: {
      source: (zscoreList || []).sort((a, b) => a.name - b.name)
    },
    series: [
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        encode: {
          x: 'name',
          y: 'value'
        }
      }
    ]
  }

  return <ECharts chartId={chartId} height="300px" width="200px" data={[{}]} chartType="custom" option={option}/>
}

const McjOrgMemberDailyReport = ({organizationId, userId}) =>{
  const [report, setReport] = useState();

  useEffect(()=>{
    getChartData();
  }, [])
  
  const getChartData = async () =>{
    const {status, data} = await McjOrgMemberDailyReportService.getAll({
      'organization_id':organizationId,
      'user_id':userId,
    })
    if(status === 200){
      setReport(data.org_member_daily_report?.[0]);
    }
  }

  if(report){
    return(
      <div id="ct">
        <div className="card-content p20">
          <h5>Your activity for {Cs.formatUpdateDate(report.created_at)}</h5>
          <div className="flex wrap scroll-auto vh-80">
            <div className="p-15 text-center bg-ltint br-white w-200">
              <h4 className="fw500 m-b-5"># of Cases</h4>
              <div className="badge-2">{report.case_active}</div>
            </div>
            <div className="p-15 text-center bg-ltint br-white w-200">
              <h4 className="fw500 m-b-5"># Visits made yesterday</h4>
              <div className="badge-2">{report.yesterday_visits}</div>
            </div>
            <div className="p-15 text-center bg-ltint br-white w-200">
              <h4 className="fw500 m-b-5"># Visits missed</h4>
              <div className="badge-2">{report.missed_schedules}</div>
            </div>
            <div className="p-15 text-center bg-ltint br-white w-200">
              <h4 className="fw500 m-b-5"># SUW Cases</h4>
              <div className="badge-2">{report.sum}</div>
            </div>
            <div className="p-15 text-center bg-ltint br-white w-200">
              <h4 className="fw500 m-b-5"># MUW Cases</h4>
              <div className="badge-2">{report.muw}</div>
            </div>
            <div className="p-15 text-center bg-ltint br-white w-200">
              <h4 className="fw500 m-b-5"># Upcoming Case Visits</h4>
              <div className="badge-2">{report.upcoming_schedules}</div>
            </div>
            <div className="p-15 text-center bg-ltint br-white w-200">
              <h4 className="fw500 m-b-5"># No Activity Days</h4>
              <div className="badge-2">{report.no_activity_days}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return null;
  }
}

const MCJCaseScheduleReport = ({organizationId, userId}) =>{
  const [reports, setReports] = useState([])

  useEffect(()=>{
    getChartData()
  }, [])
  
  const getChartData = async () =>{
    const {status, data} = await OrganizationMemberService.getChartReport({
      'report_type':'mcj_case_schedule_report',
      'organization_id':organizationId,
      'user_id':userId,
    })
    if(status === 200){
      setReports(data.chart_data)
    }
  }

  const sort = () =>{
    //TODO
  }

  return(
    <div id="ct">
      <div className="p-l-10 p-r-5">
        <div className="bold-600 f16">Target Objectives Review</div>
      </div>
      <div className="table-fix-head">
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={()=>sort('name')}>
                Name 
              </TH>
              <TH onClick={()=>sort('total')}>
                Assessment 
              </TH>
              <TH onClick={()=>sort('anc_count')}>
                ANC 
              </TH>
              <TH onClick={()=>sort('pnc_count')}>
                PNC le_5_mos 
              </TH>
              <TH onClick={()=>sort('pnc_count')}>
                PNC gte_5_mos
              </TH>
            </TR>
          </THead>
          <TBody>
            <MCJScheduleTableData reports={reports}/>
          </TBody>
        </Table>
      </div>
    </div>
  )
}

const BadgeGrey = ({count}) => <span className="badge-2 bg-grey-4 m-r-10">{count}</span>;

const MCJScheduleTableData = ({reports}) => reports.map((r, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {r.case_name}
        </TD>
        <TD>
          <AddCaseAssessmentIco className="m-l-15 highlight"
            journeyProfileId={r.journey_profile_id}
            assessUserId={r.created_by}
            count={r.case_assessment_count}
            tooltip="Add Assessment"
            assessmentData={{
              'assessment_type': 'schedule_actions',
              'assessment_type_sid': 'schedule_actions',
            }}>
            <span className="far fa-list-alt font-18"/>
          </AddCaseAssessmentIco>
        </TD>
        <TD className="text-gray">
          <BadgeGrey count={r.completed_anc}/> 
          <BadgeGrey count={r.total_anc}/>
        </TD>
        <TD className="text-gray">
          <BadgeGrey count={r.completed_pnc_lt_5m}/> 
          <BadgeGrey count={r.total_pnc_lt_5m}/> 
        </TD>
        <TD className="text-gray">
          <BadgeGrey count={r.completed_pnc_gte_5m}/> 
          <BadgeGrey count={r.total_pnc_gte_5m}/>
        </TD>
      </TR>
    )
})

const ScheduleAdherencePoint = ({organizationId, userId}) =>{
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [data, setData] = useState([])

  useEffect(()=>{
    getChartData()
  }, [])
  
  const getChartData = async () =>{
    const {status, data:{chart_data}} = await OrganizationMemberService.getChartReport({
      'report_type':'mcj_schedule_adherence_count',
      'user_id':userId,
      'organization_id':organizationId,
    })
    setData(chart_data)
  }

  const legend = {show:true}
  const colors = ['red', 'green', '#B561F8']

  return(
    <ECharts chartId="mcj_schedule_points" 
      chartType="pie" 
      data={data || []} 
      formatter={(p) => `${p.data.name}-${p.data.value}`} 
      legend={legend}
      colors={colors}
      label={{
        show: true,
        formatter: function (p) {
          return `${p.data.name}-${p.data.value}`
        }
      }}/>
  )
}

const ScheduleAdherenceChartIco = ({userId, orgId, className, children}) =>{
  const [isOpen, setIsOpen] = useState()

  return(
    <>
      <div onClick={(e)=>{e.stopPropagation();setIsOpen(true)}} className={`uline-hov ${className}`}>
        {children}
      </div>
      {isOpen &&
        <GenericModal component={ScheduleAdherencePoint} 
        toggleModal={()=>setIsOpen(false)} 
        userId={userId} organizationId={orgId} />
      }
    </>
  )
  
}

const MeasureGrowth_ScheduleAdherencePts = ({organizationId, userId}) =>{
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [data, setData] = useState([])
  const {getBgColorByZscore} = useActivityHook()
  const {current:scope} = useRef({})

  useEffect(()=>{
    getChartData()
  }, [])
  
  const getChartData = async () =>{
    const {status, data:{chart_data}} = await OrganizationMemberService.getChartReport({
      'report_type':'mcj_measure_growth_schedule_adherence_pts',
      'user_id':userId,
      'organization_id':organizationId,
    })

    scope.colors = []
    for(let d of chart_data){
      scope.colors.unshift(getBgColorByZscore(d.last_weight_zscore)?.r1?.color)
    }
    
    setData(chart_data)
  }

  return(
    <ECharts chartId="mcj_schedule_points" 
      chartType="scatter" 
      data={data || []} 
      title = {{
        text: 'Measure Growth Vs Schedule Score'
      }}
      formatter={(p)=>(p.componentType === 'markPoint') ? p.data.value : `${p.data.x} / ${p.data.y} entries`}
      xAxis={{name:'Measure Growth', type:'value'}}
      yAxis={{name:'Schedule Score', type:'value'}}
      colors={scope.colors}
    />
  )
}

const MeasureGrowth_ScheduleAdherencePtsIco = ({userId, orgId, className, children}) =>{
  const [isOpen, setIsOpen] = useState()

  return(
    <>
      <div onClick={(e)=>{e.stopPropagation();setIsOpen(true)}} className={`uline-hov ${className}`}>
        {children}
      </div>
      {isOpen &&
        <GenericModal component={MeasureGrowth_ScheduleAdherencePts} 
        toggleModal={()=>setIsOpen(false)} 
        userId={userId} organizationId={orgId} />
      }
    </>
  )
  
}

export {
  McjOrgMemberDailyReport, MeasureGrowthZscoreChartByCase, AssessCfChartBtn, ProteinIntakeChart, 
  ProteinIntakeChartBtn, AssessCFChart, ChartReports, ChartBtn, MCJGroupByPregnancyStage, 
  MCJCaseScheduleAdherence, CaseActivityCountTable, MCJCaseScheduleReport, 
  ScheduleAdherenceChartIco, MeasureGrowth_ScheduleAdherencePtsIco, MCJCaseTimeline
};