import React, {Fragment} from 'react';
import SortDirection from './SortDirection';
import useSort from '../../hooks/useSorting';
import {TableColumnData} from './FormFieldsView';
import {getLocale} from '../FormBuilder/FieldLabel';
import {usePageLocale} from "../../hooks/useLocale";

const DynamicTable = (props) => {
  let {
  	requestParams, onSort, specialFields=[], formFields=[], formDataList=[], specialFieldsLastCol=[],
  	tClass, thClass, tbClass, OptionsEle, onRowClick, currentLocale, isSortable=true,
    dataKey="data", dataSourceKey="data_source", tableType="dataInRow", formFieldViewEle, sortColPrefix
  } = props;	
  
  const {labelLocale:tableHeadLocale} = usePageLocale(currentLocale, 'commonTableHeaders');

  const {sort} = useSort(requestParams, onSort);

  const SortDirIco = ({column}) =>{
    return (
      <SortDirection sortColumn={requestParams.sort_column} column={column} reverse={requestParams.sort_direction}/>
    )
  }

  if(tableType === "dataInRow"){
    return(
      <table className={tClass}>
        <thead className={thClass}>
          <tr>
            <TableHeader headers={specialFields} {...{isSortable, requestParams, sort, currentLocale, getLocale}}/>
          	<TableHeader headers={formFields} {...{isSortable, requestParams, sort, currentLocale, getLocale, sortColPrefix}}/>
            <TableHeader headers={specialFieldsLastCol} {...{isSortable, requestParams, sort, currentLocale, getLocale}}/>
          	<th onClick={()=>sort('created_at')} className='text-center miw-95' label="Option">
              {tableHeadLocale(3) || "Actions"}
              <SortDirIco column="created_at"/>
      		  </th>
          </tr>
        </thead>
        <tbody>
          <TableRow list={formDataList} {...{currentLocale, dataKey, dataSourceKey, OptionsEle, onRowClick, specialFields, specialFieldsLastCol, formFields, formFieldViewEle}}/> 
        </tbody>
      </table>	
    )
  }else if(tableType = "dataInColumn"){
    return(  
      <table className={tClass + " col-table"}>
        <tbody>
          <tr className="tr-fix-head orientation">
            <TableRowHeader headers={specialFields} {...{isSortable, requestParams, sort, currentLocale, getLocale}}/>
            <TableRowHeader headers={formFields} {...{isSortable, requestParams, sort, currentLocale, getLocale}}/>
            <TableRowHeader headers={specialFieldsLastCol} {...{isSortable, requestParams, sort, currentLocale, getLocale}}/>
            {OptionsEle ? <td className='fw500 text-center mxw-200'>Option</td> : null}
          </tr>
          <TableRow list={formDataList} className="orientation" {...{currentLocale, dataKey, dataSourceKey, OptionsEle, onRowClick, specialFields, specialFieldsLastCol, formFields}}/> 
        </tbody>
      </table>
    )
  }
}

const TableRowHeader = ({isSortable, requestParams, headers, sort, currentLocale, getLocale}) => headers.map((h, k) => {
  let attribute = {};
  if(isSortable)
    attribute.onClick = () => sort(h.sort_column || h.client_id);

  return(  
    <td key={k} className='text-center mxw-200 fw500' {...attribute}>
      {getLocale(h, currentLocale)} 
      {isSortable ?
        <SortDirection sortColumn={requestParams.sort_column} 
        column={h.sort_column || h.client_id} reverse={requestParams.sort_direction}/> 
        :
        null
      }
    </td>   
  )
})

const TableHeader = ({isSortable, sortColPrefix, requestParams, headers, sort, currentLocale, getLocale}) => headers.map((h, k) => {
  let attribute = {};
  let sortCol = '';
  if(isSortable){
    if(h.sort_column){
      sortCol = h.sort_column;
    } else if(h.client_id){
      sortCol = sortColPrefix ? `${sortColPrefix}.data.${h.client_id}` : `data.${h.client_id}`;
    }
    attribute.onClick = () => sort(sortCol);
  }

  return(  
    <th key={k} className='text-center miw-95' {...attribute}>
      {getLocale(h, currentLocale)} 
      {isSortable ?
        <SortDirection sortColumn={requestParams.sort_column} 
        column={sortCol} reverse={requestParams.sort_direction}/> 
        :
        null
      }
    </th>   
  )
})

const TableRow = ({currentLocale, OptionsEle, list, className='', onRowClick, specialFields, specialFieldsLastCol, formFields, formFieldViewEle, dataKey, dataSourceKey}) => list.map((l, k) => {
  let dataSource = l[dataSourceKey] || {};
  let data = l[dataKey] || {};

  return(  
    <tr className={className} key={k} onClick={()=>onRowClick && onRowClick(l)}>
      <SpecialFieldData specialFields={specialFields} data={data} row={l} rowIdx={k}/>  
      <TableColumnData {...{formFields, data, dataSource, formFieldViewEle, currentLocale}}/>  
      <SpecialFieldData specialFields={specialFieldsLastCol} data={data} row={l} rowIdx={k}/>
      {OptionsEle && <OptionsEle item={l} rowIdx={k}/>}
    </tr>
  )
})

const SpecialFieldData = ({rowIdx, specialFields, data, row}) => specialFields.map((f, k) => {
  if(f.ele){
    return (
      <td className="text-center" key={k}>
        {f.ele(f.client_id, data, row, rowIdx, f.onClick)}
      </td>
    )
  }else if(f.component){
    return (
      <td className="text-center" key={k}>
        <f.component clientId={f.client_id} data={data} row={row} rowIdx={rowIdx} onClick={f.onClick}/>
      </td>
    )
  }else{
    return(  
      <td className="text-center" key={k}>
        {data[f.client_id]}
      </td>
    )
  }
})

export default DynamicTable;