import { useState, useEffect } from 'react';
import Cs from "../../services/CommonService";
import FormSubmissionReviewService from './FormSubmissionReviewService';
import { useCurrentUserHook } from "../Authentication/useUserHook";

const useReviewHook = ({itemId, itemType, organizationId, isReviewEnabled=false, statuses=[], dataSourceId}) => {
  const {currentUser} = useCurrentUserHook();

  const [isReviewLoading, setReviewLoading] = useState(isReviewEnabled);
  const [reviewByClientId, setReviewByClientId] = useState({});
  const [reviewOptions, setReviewOptions] = useState([]);

  useEffect(() => {
    if(isReviewEnabled) getReviews();
  }, [])

  const getReviews = async () =>{
    if(itemId && itemType){
      const {status, data} = await FormSubmissionReviewService.getAll({
        'organization_id':organizationId,
        'item_type':itemType,
        'item_id':itemId,
        'statuses':statuses,
        'data_source_params': {
          'data_source_id': [dataSourceId],
          'locale': currentUser.current_locale,
        }
      })
      if(status === 200){
        setReviewOptions(data.data_source_list?.f503_weight_feedback?.options);
        setReviewByClientId(Cs.listToObj(data.reviews, 'field_client_id'));    
        setReviewLoading(false);
      }
    }else{
      setReviewLoading(false);
    }
  }

  return {
    reviewOptions,
    isReviewLoading,
    reviewByClientId, 
  }
}

export {useReviewHook};