import React from 'react'
import EditDataSourceIcon from './EditDataSourceIcon'
import FormAlertMessage from "./FormAlertMessage"
import { HelpIcon } from "../Help/HelpSidePanel"
import { FieldAnnotationBtn } from "./FormFieldNotes/FormFieldNotesList"
import { ImageReplacement } from "../DataSources/FormFieldImageSuggestion"
import { usePageLocale } from "../../hooks/useLocale"
import { SpanRichTextView } from '../Common/RichTextView'

const FieldLabel = ({formId, field, isFormWizard, labelAttributes, openFieldLabelModal, openDataSourceModal, currentLocale}) => {
   field.label = getLocaleLabel(field, currentLocale)
   
   return(
      <div className={"d-flex alignbaseline justspacebetween "+(isFormWizard?'m-b-5':'')}>
      	<label {...labelAttributes}>
            {field.icon && <i className={field.icon + " font-20 m-r-10"} style={{color:field.icon_color}}/>}
            <SpanRichTextView html={field.label}/>
         </label>	
         <div className="text-muted-2">
            {openDataSourceModal && <EditDataSourceIcon field={field} open={openDataSourceModal}/>}
            <EditDataSourceIcon field={field} open={openFieldLabelModal} iconClass="fas fa-edit"/>
            <FormAlertMessage field={field} formId={formId}/>
            <HelpIcon recordIds={field.help_id}/>
            <FieldAnnotationBtn field={field} formId={formId}/>
            {field.data_source_id ? <ImageReplacement formField={field} className="f18 m-l-15"/> : null}
         </div>
      </div>
   )  
}

const getLocaleLabel = (field, currentLocale, key="label") => {
   return field.locale?.[key]?.[currentLocale] || field[key];
}

const OptionLabel = ({option, currentLocale}) => {
   if(option.locale?.[currentLocale]?.label){
      return option.label + " / " + option.locale?.[currentLocale]?.label;
   }
   return option.label;
}

const getKeyByLocale = (data, currentLocale, key="label") => {
   return data.locale?.[currentLocale]?.[key] || data[key];
}

const TabLabel = ({tab, currentLocale}) => {
   return( 
      <span>
         {tab.locale?.[currentLocale] || tab.label}
         <HelpIcon recordIds={tab.help_id}/>
      </span>
   )
}

const Description = ({description, currentLocale, locale, showOnHover=false}) => {
   return(
      <SpanRichTextView className={`${showOnHover?'rich-text':'rich-text rich-text-2'} fts-13 pull-left`} html={(locale?.[currentLocale] || description)}></SpanRichTextView>   
   )
}

const SelectListPlaceholder = ({field, currentLocale}) => {
   return(
      <SpanRichTextView html={getLocaleLabel(field, currentLocale ,'description')}/>
   )
}

const FieldReview = ({review}) =>{
   if(review){
      return(<span className="fts-13 pull-left lred">{review.message} - {review.user?.name}</span>)
   }

   return null
}

const FormTitle = ({title, locale, currentLocale, className=''}) => {
   return (
      <span className={'pull-left '+className}>{locale?.[currentLocale] || title}</span>
   )
}

const FormDescription = ({details, currentLocale, locale}) => {
   return(
      <SpanRichTextView className="form-details f14" html={(locale?.[currentLocale] || details)}></SpanRichTextView>   
   )
}

const getLocaleData = (data, currentLocale='english', localeKey='label') =>{
   return data?.locale_data?.[currentLocale]?.[localeKey] || data?.[localeKey]
}

const getLocale = (data, currentLocale='english', localeKey='label') =>{
   return data?.[currentLocale]?.[localeKey] || data?.[localeKey]
}

const RequiredMsg = ({field, currentLocale='english'}) => {
   const msg = getLocaleErrorMsg(field.locale, 'required_msg', currentLocale)
   if(msg) return msg
   
   if(['checkbox', 'select', 'autocomplete', 'multi_select', 'input_tags', 'radio'].indexOf(field.component)>-1){
      return "You must select a value from the list that is displayed."      
   }

   return requiredMsgTextInput
}

const requiredMsgTextInput = "You must enter information in this field";

const WarningMsg = ({field, lkey, currentLocale='english'}) => {
   const msg = getLocaleErrorMsg(field.locale, lkey, currentLocale);
   return msg || 'Please check the value you have submitted to make sure it is correct.';
}

const getLocaleErrorMsg = (locale, key, currentLocale) => locale?.[key]?.[currentLocale];

const FieldPoint = ({userRefId, clientId, point, negPoint, currentLocale}) => {
   const { labelLocale } = usePageLocale(currentLocale, "quizFormLabels");

   return (
      <>
         {point !=null ? <span className="text-right text-muted-2" label="Correct answer">{labelLocale(9)}: {point} {labelLocale(11)}</span> : null}
         {negPoint > 0 ? <span className="text-right text-muted-2" label="Incorrect answer">{labelLocale(10)}: - {negPoint} {labelLocale(11)}</span> : null}
         {userRefId ? <span className="text-right text-muted-2" label="Incorrect answer">k{clientId}{userRefId}</span> : null}
      </>
   )
}

const SelectedCount = ({label="", show, count = 0}) => show ? <div className="m-t-5 f14 text-blue fw500">{count} {label} Selected</div> : null;


export {SelectListPlaceholder, SelectedCount, getLocaleLabel, getKeyByLocale, FieldPoint, FormTitle, FormDescription, FieldLabel, OptionLabel, Description, TabLabel, getLocaleData, getLocale, RequiredMsg, WarningMsg, requiredMsgTextInput, FieldReview};