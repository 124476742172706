import env from '../../../../env';
import axios from 'axios';
import BaseService from '../../../../services/BaseService';

class PlannerStepService extends BaseService{

	async uploadTxt(req) {
		return await axios.post(`${env.health_backend}health/upload/planner_steps`, req);
	}
  
}

export default new PlannerStepService('health_backend', 'health/planner_steps');