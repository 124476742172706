import React ,{useState, useEffect, useRef} from "react";
import Cs from "../../../services/CommonService";
import {FormFieldCard} from "../../Common/FormFieldsView";
import {DraftBadge} from "../../Common/NotificationPill";
import useStyle from '../../../hooks/useStyle';
import { PillDsList } from "../../Common/NotificationPill";
import CaseAssessmentService from "./CaseAssessmentService";
import FormTemplateService from "../../../services/FormTemplateService";
import { useCurrentUserHook } from "../../Authentication/useUserHook";
import {useDataSourceLocale} from '../../../hooks/useLocale';

const AssessmentCard = (props) => {
  useStyle('/css/tile_components.css', true);

  const {currentUser} = useCurrentUserHook();

  const {formFields, assessment,  toggleModal, enableScroll=true, caseId, caseName} = props;
  
  return (
    <div id="ct">
      <div className="bg-white brd-10 card-shadow m-b-15">
        <Header assessment={assessment} caseId={caseId} caseName={caseName}/>
        <div className="card-content p20">
          <div className={"b-tab-table active "+(enableScroll?'scroll-auto ht-400':'')}>
            <div className="p-b-15 m-b-15 m-t-15">
              <FormFieldCard formFields={formFields} 
                dataSource={assessment.data_source || {}}
                data={assessment.data || {}}
                currentLocale={currentUser.current_locale}/>
            </div>
          </div>
        </div>
        <Footer {...{  assessment, currentUser, toggleModal}}/>
      </div>
    </div>
  )
}

const Header = ({caseId, caseName, assessment}) => (
  <div className="card-title card-title-gray flex coldir p20">
    <h4 className="fw700 f18">
      {caseId} {caseName} {assessment.data_source?.user_suggestion?.label}
    </h4>
    <div className="f14 m-t-10 fw500">
      {assessment.user_name}
      &nbsp;-&nbsp; 
      {Cs.formatUpdateDate(assessment.updated_at)}
      <DraftBadge isDraft={assessment.draft}/>
    </div>
  </div>
)

const Footer = ({ assessment, currentUser, toggleModal }) => {
  return(
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25">{assessment.record_id}</li>
      </ul>
    </div>
  )
}

const formFieldViewEle = {
  issues_gen_engagement: (clientId, data, ds) => {
    return (
      <div className="flex wrap gap-5">
        <PillDsList 
          list={ds.issues_gen_engagement} 
          dataKey='label' 
          styleName="badge bg-highlight white mxw-150 m-r-5 m-b-5"/>
      </div>
    );
  },
  issues_case_level: (clientId, data, ds) => {
    return (
      <div  className="flex wrap gap-5">
        <PillDsList 
          list={ds.issues_case_level} 
          dataKey='label' 
          styleName="badge bg-lred white mxw-150 m-r-5 m-b-5"/>
      </div>
    );
  },
  assessment_proper_actions: (clientId, data, ds) => {
    return (
      <div  className="flex wrap gap-5">
        <PillDsList 
          list={ds.assessment_proper_actions} 
          dataKey='label' 
          styleName="badge bg-lgreen white mxw-150 m-r-5 m-b-5"/>
      </div>
    );
  }
}

const CaseAssessment = ({id, journey}) =>{
  const {currentUser} = useCurrentUserHook();
  let {current:scope} = useRef({});

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [formFields, setFormFields] = useState([]);

  const {setDslLocale} = useDataSourceLocale(currentUser.current_locale);

  useEffect(() => {
    scope.requestParams = {
      'id':id,
      'page':1,
      'per_page':1,
      'journey_profile_id':journey.id,
      'locale': currentUser.current_locale
    } 
    getAll();
    getFormFields();
  }, [])

  const getAll = async () =>{
    setIsLoading(true);
    const {status, data} = await CaseAssessmentService.getAll(scope.requestParams);
    if(status === 200){
      setDslLocale(data.case_assessments, data.dsl_locales);
      setList(data.case_assessments[0]);
      setIsLoading(false);
    }
  }

  const getFormFields = async () => { 
    setIsLoading(true);
    const {status, data} = await FormTemplateService.getFormFields({
      'ids':[593], 
      'is_table_view':true,
      'locale':currentUser.current_locale
    });
    if(status === 200){
      setFormFields(data.form_fields[593]);
      setIsLoading(false);
    }
  }

  return(
    <AssessmentCard 
      assessment={list}
      formFields={formFields}
      formFieldViewEle={formFieldViewEle}
      caseId={journey?.record_id}
      caseName={journey?.data?.record_title}/>
  )
}

export {formFieldViewEle, AssessmentCard, CaseAssessment};