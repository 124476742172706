import React, { Fragment } from 'react';

export const Rating = ({onSelect, className='', sumOfRating=0, noOfRating=1}) => {
   /*sumOfRating - summ of all the rating fields*/
   /*noOfRating - no of rating fields in multi rating modal*/

   if(sumOfRating && noOfRating){
      const rating = parseInt(sumOfRating/noOfRating);
      const noRating = ( 5 - rating );

      const ratingArray = [...Array(rating)];
      const noRatingArray = [...Array(noRating)];

      const RatingView = () => ratingArray.map((o, k) =>
         <i className="fas fa-star color-highlight" key={k}></i>
      )

      const NoRatingView = () => noRatingArray.map((o, k) =>
         <i className="far fa-star color-highlight" key={k}></i>
      )

      let props = {};
      if(onSelect) props.onClick = () =>onSelect();

      return (
         <div className={className} {...props}>
            <RatingView/>
            <NoRatingView/>
         </div>
      )
   }
      
   return null;
}

export default Rating;